const navigationItems = {
  lithiumSupply: {
    parents: [
      {
        name: 'Mined',
        link: '/lithium/supply/mined/companies/overview/',
        icon: 'menu/raw-material',
        type: 'mined',
      },
      {
        name: 'Chemical',
        link: '/lithium/supply/chemical/companies/overview/',
        icon: 'menu/chemical',
        type: 'chemical',
      },
    ],
    children: [
      {
        name: 'Company',
        link: '/lithium/supply/{0}/companies/overview/',
        type: 'companies',
        parentType: ['mined', 'chemical'],
      },
      {
        name: 'Country',
        link: '/lithium/supply/{0}/countries/overview/',
        type: 'countries',
        parentType: ['mined', 'chemical'],
      },
      {
        name: 'Region',
        link: '/lithium/supply/{0}/regions/overview/',
        type: 'regions',
        parentType: ['mined', 'chemical'],
      },
      {
        name: 'Plant',
        link: '/lithium/supply/{0}/plants/overview/',
        type: 'plants',
        parentType: ['chemical'],
      },
      {
        name: 'Feedstock',
        link: '/lithium/supply/{0}/feedstocks/overview/',
        type: 'feedstocks',
        parentType: ['chemical'],
      },
      {
        name: 'Product',
        link: '/lithium/supply/{0}/products/overview/',
        type: 'products',
        parentType: ['chemical'],
      },
      {
        name: 'Mine',
        link: '/lithium/supply/{0}/mines/overview/',
        type: 'mines',
        parentType: ['mined'],
      },
      {
        name: 'Ore Type',
        link: '/lithium/supply/{0}/ore-type/overview/',
        type: 'ore-type',
        parentType: ['mined'],
      },
    ],
  },
  naturalGraphiteSupply: {
    parents: [
      { name: 'Mine', link: '/natural-graphite/supply/mines/overview/', type: 'mines' },
      {
        name: 'Company',
        link: '/natural-graphite/supply/companies/overview/',
        type: 'companies',
      },
      {
        name: 'Country',
        link: '/natural-graphite/supply/countries/overview/',
        type: 'countries',
      },
      { name: 'Region', link: '/natural-graphite/supply/regions/overview/', type: 'regions' },
    ],
  },
  batteryCellsSupply: {
    parents: [
      { name: 'Plant', link: '/battery-cells/supply/plants/overview/', type: 'plants' },
      {
        name: 'Company',
        link: '/battery-cells/supply/companies/overview/',
        type: 'companies',
      },
      {
        name: 'Country',
        link: '/battery-cells/supply/countries/overview/',
        type: 'countries',
      },
      { name: 'Region', link: '/battery-cells/supply/regions/overview/', type: 'regions' },
    ],
  },
  anodeSupply: {
    parents: [
      { name: 'Overview', link: '/anode/supply/', type: 'supply' },
      {
        name: 'Facility',
        link: '/anode/supply/facilities/overview/',
        type: 'facilities',
      },
      {
        name: 'Company',
        link: '/anode/supply/companies/overview/',
        type: 'companies',
      },
      { name: 'Location', link: '/anode/supply/locations/overview/', type: 'locations' },
    ],
  },
  cathodeSupply: {
    parents: [
      { name: 'Overview', link: '/cathode/supply/', type: 'supply' },
      {
        name: 'Facility',
        link: '/cathode/supply/facilities/overview/',
        type: 'facilities',
      },
      {
        name: 'Company',
        link: '/cathode/supply/companies/overview/',
        type: 'companies',
      },
      { name: 'Location', link: '/cathode/supply/locations/overview/', type: 'locations' },
    ],
  },
  costs: {
    parents: [
      {
        name: 'Lithium Carbonate',
        link: '/lithium/costs/lithium-carbonate/c1/',
        icon: 'menu/raw-material',
        type: 'lithium-carbonate',
      },
      {
        name: 'Lithium Hydroxide',
        link: '/lithium/costs/lithium-hydroxide/c1/',
        icon: 'menu/raw-material',
        type: 'lithium-hydroxide',
      },
      {
        name: 'Spodumene',
        link: '/lithium/costs/spodumene/c1/',
        icon: 'menu/raw-material',
        type: 'spodumene',
      },
    ],
    children: [
      {
        name: 'C1',
        link: '/lithium/costs/{0}/c1/',
        type: 'c1',
        parentType: ['lithium-carbonate', 'lithium-hydroxide', 'spodumene'],
      },
      {
        name: 'C2',
        link: '/lithium/costs/{0}/c2/',
        type: 'c2',
        parentType: ['lithium-carbonate', 'lithium-hydroxide', 'spodumene'],
      },
      {
        name: 'C3',
        link: '/lithium/costs/{0}/c3/',
        type: 'c3',
        parentType: ['lithium-carbonate', 'lithium-hydroxide', 'spodumene'],
      },
    ],
  },
}

export { navigationItems }
