<template>
  <div>
    <TabsLinks new-styles :tabs="parentsLinksData" :selected-tab="selectedChild" />
    <TabsLinks
      new-styles
      :with-icon="false"
      :tabs="childrenTabsData"
      size="small"
      :selected-tab="selectedParent"
      :selected-current-tab="selectedChild"
      :with-return-to-overview="withReturnToOverview"
    />
  </div>
</template>

<script setup lang="ts">
import { navigationItems } from '~/constants/tabNavigation'

const props = withDefaults(
  defineProps<{
    withReturnToOverview?: boolean
    typeMenuData: string
    typeChild:
      | 'mines'
      | 'companies'
      | 'countries'
      | 'regions'
      | 'plants'
      | 'feedstocks'
      | 'ore-type'
      | 'products'
      | 'c1'
    typeParent: 'mined' | 'chemical' | 'lithium-carbonate' | 'lithium-hydroxide' | 'spodumene'
    queryParams?: object
  }>(),
  {
    queryParams: () => null,
    withReturnToOverview: true,
  },
)

const params = computed(() => {
  const query = props.queryParams ?? {}
  return new URLSearchParams(query as URLSearchParams).toString()
})

const menuData = navigationItems[props.typeMenuData]
const parentsLinksData = computed(() =>
  menuData.parents.map((item) => {
    const link = params.value !== '' ? `${item.link}?${params.value}` : item.link
    return { ...item, link }
  }),
)

const parent = computed(() => props.typeParent)

const childrenTabsData = computed(() =>
  menuData.children
    .filter((item) => item.parentType.includes(parent.value))
    .map((item) => {
      const link = params.value !== '' ? `${item.link}?${params.value}` : item.link
      return { ...item, link }
    }),
)
const selectedChild = computed(() =>
  childrenTabsData.value.find((item) => item.type === props.typeChild),
)
const selectedParent = computed(() =>
  parentsLinksData.value.find((item) => item.type === parent.value),
)
</script>
